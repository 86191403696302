import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FancyCard from "../components/fancy-card"
import Separator from '../components/separator'

const IndexPage = () => (
  <Layout>
    <SEO title="Надежда Резникова — психолог в Ванкувере" />

    <FancyCard>
    <h1>Надежда Резникова</h1>

    <p>Я квалифицированный практикующий психолог в Ванкувере. В своей профессии я работаю со всем, что важно человеку:
      с любыми состояниями, чувствами, мыслями, задачами и потребностями. Я верю в человека! В его возможность изменить
      себя и свой мир так, чтобы жизнь была наполнена радостью и свершениями...&nbsp;
      <a href="https://blog.nedbright.com/napravleniya-deyatelnosti" className="more">Подробнее</a>
    </p>

    <Separator />

    <ul className="menu">
        <li><a href="https://blog.nedbright.com/">Блог</a></li>
        <li><a href="https://blog.nedbright.com/about/">Обо мне</a></li>
        <li><a href="https://blog.nedbright.com/napravleniya-deyatelnosti/">Деятельность</a></li>
        <li><a href="https://blog.nedbright.com/kontaktyi/">Контакты</a></li>
    </ul>
    </FancyCard>
  </Layout>
)

export default IndexPage
