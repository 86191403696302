import React from "react"

import '../styles/separator.css';

const Separator = () => (
  <>
    <div className="separator"></div>
  </>
);

export default Separator;
