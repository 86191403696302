import React from "react"

import '../styles/fancy-card.css';

const FancyCard = ({children}) => (
  <>
    <div className="fancy-card">
      {children}
    </div>
  </>
);

export default FancyCard;
